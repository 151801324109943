import cx from 'classnames'
import DetailTableHeader from 'component/CFSPage/DetailTableHeader'
import detailPageStyle from 'component/detailPageStyle'
import CheckBoxInput from 'component/field/CheckBoxInput'
import SelectInput from 'component/field/SelectInput'
import TextInput from 'component/field/TextInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import {
  CZ_DPH,
  DATE_FORMAT,
  DATE_TIME_DISPLAY_FORMAT,
  DB_DATE_FORMAT,
  SK_DPH,
} from 'helper/constants'
import {isAdminUser, isLoggedUser, mapErrorResponseToForm} from 'helper/functions'
import {composeValidators, itemTaxValidation, maxDate, minDate, required} from 'helper/validations'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {getCfs, getInvoiceChangeLog, getInvoiceStatuses, patchCfs} from 'redux/action/cfsAction'
import {getFleet} from 'redux/action/fleetsAction'
import {ReactComponent as Bin} from 'style/asset/bin.svg'
import {Trans} from '@lingui/macro'
import {IconButton} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Loader from '../material/Loader'

const CFSDetail = (props) => {
  const {
    classes,
    getCfs,
    cfs,
    cfsLoading,
    getInvoiceStatuses,
    invoiceStatusList,
    invoiceStatusListLoading,
    patchCfs,
    getInvoiceChangeLog,
    changeLog,
    changeLogLoading,
    getFleet,
    fleetLoading,
  } = props

  let {id} = useParams()

  const [workItems, setWorkItems] = useState([])
  const [materialItems, setMaterialItems] = useState([])
  const [predefinedItems, setPredefinedItems] = useState([])
  const [editedValues, setEditedValues] = useState({})
  const [focusedRow, setFocusedRow] = useState()
  const [currentFleet, setCurrentFleet] = useState()

  const isSkProvider = cfs?.provider?.id === 3 || cfs?.provider?.id === 4

  const handleSetFocusedRow = (itemRow) => {
    setFocusedRow(itemRow)
  }

  const handleFieldChange = (e) => {
    if (e.target.type === 'checkbox') {
      setEditedValues((prevState) => ({...prevState, [e.target.name]: e.target.checked}))
    } else {
      setEditedValues((prevState) => ({...prevState, [e.target.name]: e.target.value}))
    }
  }

  const handleItemChange = (type, index, e, materialType) => {
    const name = e.target.name.split('.')[e.target.name.split('.').length - 1]
    const value = e.target.value

    if (type === 'work') {
      let newWorkItems = [...workItems]
      newWorkItems[index] = {...workItems[index], [name]: value}
      setWorkItems(newWorkItems)
    } else if (type === 'material') {
      const otherMaterials = [...materialItems].filter(
        (items) => items.line_type?.code !== materialType && items.line_type_code !== materialType
      )

      const newMaterial = [...materialItems].filter(
        (items) => items.line_type?.code === materialType || items.line_type_code === materialType
      )

      newMaterial[index] = {...newMaterial[index], [name]: value}

      setMaterialItems([...newMaterial, ...otherMaterials])
    } else if (type === 'predefined') {
      let newPredefined = [...predefinedItems]
      newPredefined[index] = {...predefinedItems[index], [name]: value}
      setPredefinedItems(newPredefined)
    }
  }

  const prepareValues = (values) => {
    return {
      status_id: values.status_id,
      status_description: values.status_description,
      insured_event: !!values.insured_event,
      loan_vehicle: !!values.loan_vehicle,
      ...editedValues,
      date_due: values.date_due && moment(values.date_due, DATE_FORMAT).format(DB_DATE_FORMAT),
      date_issue:
        values.date_issue && moment(values.date_issue, DATE_FORMAT).format(DB_DATE_FORMAT),
      date_uzp: values.date_uzp && moment(values.date_uzp, DATE_FORMAT).format(DB_DATE_FORMAT),
      date_uzp_outgoing: values.date_uzp_outgoing
        ? moment(values.date_uzp_outgoing, DATE_FORMAT).format(DB_DATE_FORMAT)
        : null,
      items: [...workItems, ...materialItems, ...predefinedItems].map((item) => {
        const itemType = item.line_type_code ? item.line_type_code : item.line_type?.code
        const itemId = item.id.toString().includes('NEW') ? null : item.id
        delete item.line_type
        return {
          ...item,
          line_type_code: itemType,
          id: itemId,
          quantity: Number(item.quantity),
          tax: Number(item.tax),
          unit_price: Number(item.unit_price),
          discount: Number(item.discount),
        }
      }),
    }
  }

  const enhanceError = (error) => {
    if (error) {
      const newError = [...error]
      return newError.map((err) => {
        if (err.source.includes('.')) {
          return {...err, source: err.source.split('.')?.[1]}
        } else {
          return err
        }
      })
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    return patchCfs(id, preparedValues)
      .then(() => {
        getInvoiceChangeLog(id)
        handleGetCfs()
      })
      .catch((err) => {
        const error = enhanceError(err)
        return mapErrorResponseToForm(error)
      })
  }

  const getInitialValues = () => {
    return {
      ...cfs,
      workItem: workItems,
      goodItem: materialItems?.filter(
        (item) => item?.line_type?.code === 'GOOD' || item?.line_type_code === 'GOOD'
      ),
      elitItem: materialItems?.filter(
        (item) => item?.line_type?.code === 'ELIT' || item?.line_type_code === 'ELIT'
      ),
      oeItem: materialItems?.filter(
        (item) => item?.line_type?.code === 'OE' || item?.line_type_code === 'OE'
      ),
      predefinedItem: predefinedItems,
      date_issue: cfs.date_issue ? moment(cfs.date_issue).format(DATE_FORMAT) : null,
      date_due: cfs.date_due ? moment(cfs.date_due).format(DATE_FORMAT) : null,
      date_uzp: cfs.date_uzp ? moment(cfs.date_uzp).format(DATE_FORMAT) : null,
      date_uzp_outgoing: cfs.date_uzp_outgoing
        ? moment(cfs.date_uzp_outgoing).format(DATE_FORMAT)
        : null,
      ...editedValues,
    }
  }

  const getPriceWithoutTax = (item) => {
    if (item) {
      const discount = !item.discount ? 1 : (100 - Number(item.discount)) / 100
      const unitPrice = !item?.unit_price ? 0 : Number(item?.unit_price)
      const quantity = !item?.quantity ? 0 : Number(item?.quantity)
      return Math.round(unitPrice * quantity * discount * 100) / 100
    } else {
      return 0
    }
  }

  const getTotal = (values, withTax = false) => {
    const allItems = []
    if (values) {
      values.workItem && allItems.push(...values.workItem)
      values.goodItem && allItems.push(...values.goodItem)
      values.elitItem && allItems.push(...values.elitItem)
      values.oeItem && allItems.push(...values.oeItem)
      values.predefinedItem && allItems.push(...values.predefinedItem)

      if (withTax) {
        return allItems.reduce(
          (prev, curr) =>
            prev +
            (!curr?.unit_price ? 0 : Number(curr?.unit_price)) *
              (!curr?.quantity ? 0 : Number(curr?.quantity)) *
              (!curr.discount ? 1 : (100 - Number(curr.discount)) / 100) *
              ((100 + Number(curr.tax)) / 100),
          0
        )
      } else {
        return allItems.reduce(
          (prev, curr) =>
            prev +
            (!curr?.unit_price ? 0 : Number(curr?.unit_price)) *
              (!curr?.quantity ? 0 : Number(curr?.quantity)) *
              (!curr.discount ? 1 : (100 - Number(curr.discount)) / 100),
          0
        )
      }
    } else {
      return 0
    }
  }

  const attachmentColumns = [
    {
      name: 'file_name',
      label: <Trans>Name</Trans>,
      render: (val, row) => (
        <a
          href={row.file_uri}
          target={'_blank'}
          title="Stáhnout"
          download
          className={cx(row.file_uri && classes.downloadLink)}
        >
          {val}
        </a>
      ),
    },
    {
      name: 'type',
      label: <Trans>Type</Trans>,
      render: (val) => val?.name,
    },
  ]

  const changeLogColumns = [
    {
      name: 'field',
      label: <Trans>Field</Trans>,
    },
    {
      name: 'step_before',
      label: <Trans>Before</Trans>,
    },
    {
      name: 'step_after',
      label: <Trans>After</Trans>,
    },
    {
      name: 'username',
      label: <Trans>Username</Trans>,
    },
    {
      name: 'status_description',
      label: <Trans>Status description</Trans>,
      render: (val) => {
        if (val?.length >= 66) {
          return (
            <CustomTooltip title={val} className={classes.statusDescriptionInChangeLogWrapper}>
              <span className={classes.statusDescriptionInChangeLog}>{val}</span>
            </CustomTooltip>
          )
        } else {
          return val
        }
      },
    },
    {
      name: 'custom_timestamp',
      label: <Trans>Timestamp</Trans>,
      render: (val) => val && moment(val).format(DATE_TIME_DISPLAY_FORMAT),
    },
  ]

  const handleAddItem = (type, dateIssue) => () => {
    const hasSKNewDPH = dateIssue && moment(dateIssue, DATE_FORMAT).isAfter('2024-12-31')

    if (type === 'work') {
      setWorkItems((prevState) => [
        ...prevState,
        {
          id: `NEW-WORK-${Math.random()}`,
          code: '',
          description: '',
          unit_price: 0,
          quantity: 0,
          tax: isSkProvider ? (hasSKNewDPH ? 23 : 20) : 21,
          discount: 0,
          line_type: {code: 'WORK'},
          line_type_code: 'WORK',
        },
      ])
    } else if (type === 'material') {
      setMaterialItems((prevState) => [
        ...prevState,
        {
          id: `NEW-MAT-${Math.random()}`,
          code: '',
          description: '',
          unit_price: 0,
          quantity: 0,
          tax: isSkProvider ? (hasSKNewDPH ? 23 : 20) : 21,
          discount: 0,
          line_type: {code: 'GOOD'},
          line_type_code: 'GOOD',
        },
      ])
    } else if (type === 'predefined') {
      setPredefinedItems((prevState) => [
        ...prevState,
        {
          id: `NEW-PRE-${Math.random()}`,
          code: '',
          description: '',
          unit_price: 0,
          quantity: 0,
          tax: isSkProvider ? (hasSKNewDPH ? 23 : 20) : 21,
          discount: 0,
          line_type: {code: 'PREDEFINED'},
          line_type_code: 'PREDEFINED',
        },
      ])
    }
  }

  //finish removing items, finish handle change of items
  const handleRemoveItem = (type, index, item) => () => {
    if (type === 'work') {
      setWorkItems((prevState) => prevState.filter((val) => val.id !== item.id))
    } else if (type === 'material') {
      setMaterialItems((prevState) => prevState.filter((val) => val.id !== item.id))
    } else if (type === 'predefined') {
      setPredefinedItems((prevState) => prevState.filter((val) => val.id !== item.id))
    }
  }

  const handleGetCfs = () => {
    return getCfs(id)
      .then((res) => {
        setWorkItems(res?.items?.filter((item) => item?.line_type?.code === 'WORK'))

        setMaterialItems([
          ...res?.items?.filter((item) => item?.line_type?.code === 'GOOD'),
          ...res?.items?.filter((item) => item?.line_type?.code === 'ELIT'),
          ...res?.items?.filter((item) => item?.line_type?.code === 'OE'),
        ])

        setPredefinedItems(res?.items?.filter((item) => item?.line_type?.code === 'PREDEFINED'))

        if (res?.car?.fleet_id === 0 || res?.car?.fleet_id) {
          getFleet(res?.car?.fleet_id)
            .then((fleetRes) => {
              setCurrentFleet(`${fleetRes.customer} - ${fleetRes.customer_cfs}`)
            })
            .catch((err) => console.error(err))
        }
      })
      .catch((err) => console.error(err))
  }

  const dateEnterEventHandler = (formProps) => (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value
      const name = e.target.name

      if (value) {
        let date = value?.split(' ')?.[0]

        const dateSeparator = date.includes('.')
          ? '.'
          : date.includes('/')
          ? '/'
          : date.includes('-')
          ? '-'
          : null

        if (date?.length > 2 && date?.split(dateSeparator)?.length > 2) {
          let newValueSplit = date?.split(dateSeparator)
          date = newValueSplit
            .map((val, index) => {
              if (newValueSplit?.length - index > 1) {
                if (val?.length === 1) {
                  return `0${val}`
                }
                // last number - year
              } else if (newValueSplit?.length - 1 === index) {
                let year = val
                while (year?.length === 2) {
                  year = moment()?.year().toString()?.slice(0, 2) + year
                }
                while (year?.length < 4) {
                  year += '0'
                }
                return year
              }
              return val
            })
            .join('.')
        }

        const dateTime = `${date ? date : ''}`

        formProps.form.change(name, moment(dateTime, DATE_FORMAT).format(DATE_FORMAT))

        if (name === 'date_issue') {
          formProps.form.change(
            'date_due',
            moment(value, DATE_FORMAT).add(30, 'days').format(DATE_FORMAT)
          )
          formProps.form.change('date_uzp', moment(value, DATE_FORMAT).format(DATE_FORMAT))

          setEditedValues((prevState) => ({
            ...prevState,
            date_issue: moment(value, DATE_FORMAT).format(DATE_FORMAT),
            date_due: moment(value, DATE_FORMAT).add(30, 'days').format(DATE_FORMAT),
            date_uzp: moment(value, DATE_FORMAT).format(DATE_FORMAT),
          }))
        }
      } else {
        formProps.form.change(name, moment().format(DATE_FORMAT))

        if (name === 'date_issue') {
          formProps.form.change('date_due', moment().add(30, 'days').format(DATE_FORMAT))
          formProps.form.change('date_uzp', moment().format(DATE_FORMAT))

          setEditedValues((prevState) => ({
            ...prevState,
            date_issue: moment().format(DATE_FORMAT),
            date_due: moment().add(30, 'days').format(DATE_FORMAT),
            date_uzp: moment().format(DATE_FORMAT),
          }))
        }
      }

      e.preventDefault()
    }
  }

  const hasFormTaxError = (formValues) => {
    if (formValues) {
      const dateIssue = formValues.date_issue
      const hasSKNewDPH = dateIssue && moment(dateIssue, DATE_FORMAT).isAfter('2024-12-31')
      const items = [
        ...(formValues?.elitItem || []),
        ...(formValues?.oeItem || []),
        ...(formValues?.goodItem || []),
        ...(formValues?.workItem || []),
        ...(formValues?.predefinedItem || []),
      ]

      return hasSKNewDPH
        ? items.some((item) => parseInt(item.tax, 10) === 20)
        : items.some((item) => parseInt(item.tax, 10) === 23)
    }

    return false
  }

  useEffect(() => {
    if (isLoggedUser()) {
      handleGetCfs()
      getInvoiceStatuses().catch((err) => console.error(err))
      getInvoiceChangeLog(id).catch((err) => console.error(err))
    }
  }, [])

  useEffect(() => {
    document.getElementById('cfs-invoice')?.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
  }, [])

  const isAdmin = isAdminUser()

  return (
    <div className={classes.detailPageContainer}>
      <Form onSubmit={handleSubmit} initialValues={id !== 'new' ? getInitialValues() : {}}>
        {(formProps) => {
          const isDisabled = formProps.submitting || cfsLoading
          return (
            <form onSubmit={formProps.handleSubmit} id={'cfs-invoice'}>
              <GridItem container xs={12}>
                <GridContainer
                  spacing={4}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <BoxFullWidth p={2} className={classes.formTitle}>
                    <Trans>Status</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12} sm={9} className={classes.statusDescription}>
                    <Field
                      disabled={isDisabled}
                      name="status_description"
                      label={<Trans>Description</Trans>}
                      component={TextInput}
                      multiline
                      rows={4}
                      filters={true}
                    />
                  </GridItem>
                  <GridContainer
                    item
                    xs={12}
                    sm={3}
                    justifyContent={'flex-start'}
                    className={classes.statusControl}
                  >
                    <Field
                      disabled={isDisabled}
                      name="status_id"
                      label={<Trans>Status</Trans>}
                      component={SelectInput}
                      options={invoiceStatusList.map((status) => ({
                        name: status.description,
                        id: status.id,
                      }))}
                      loading={invoiceStatusListLoading}
                      filters={true}
                    />
                    <BoxFullWidth mt={3.5}>
                      <PrimaryButton
                        disabled={isDisabled}
                        size="small"
                        type="submit"
                        textPadding={5}
                        text={<Trans>Save</Trans>}
                      />
                    </BoxFullWidth>
                  </GridContainer>
                </GridContainer>
              </GridItem>

              <GridContainer alignItems="flex-start">
                <GridItem container xs={6} alignItems="flex-start">
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Car</Trans>
                  </BoxFullWidth>
                  <GridContainer
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <GridItem container xs={12} sm={8}>
                      <Field
                        name="car.rz"
                        label={<Trans>RZ</Trans>}
                        component={TextInput}
                        disabled={true}
                        fullWidth={false}
                        inLineInput={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={8}>
                      <Field
                        name="car.vin"
                        label={<Trans>VIN</Trans>}
                        component={TextInput}
                        disabled={true}
                        fullWidth={false}
                        inLineInput={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={8}>
                      <Field
                        name="clock"
                        label={<Trans>Mileage</Trans>}
                        component={TextInput}
                        disabled={true}
                        fullWidth={false}
                        inLineInput={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={8}>
                      <Field
                        name="vehicle"
                        label={<Trans>Car</Trans>}
                        component={TextInput}
                        disabled={true}
                        fullWidth={false}
                        inLineInput={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={8}>
                      <TextInput
                        disabled={true}
                        label={<Trans>Fleet</Trans>}
                        value={currentFleet}
                        loading={fleetLoading}
                        inLineInput={true}
                        fullWidth={false}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={8}>
                      <Field
                        name="car.is_without_vehicle"
                        label={<Trans>Without car</Trans>}
                        component={CheckBoxInput}
                        type={'checkbox'}
                        disabled={true}
                        inLineInput={true}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem container xs={6} alignItems="flex-start">
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Supplier</Trans>
                  </BoxFullWidth>
                  <GridContainer
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="supplier.customer_no"
                        label={<Trans>Customer number ELIT</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="supplier.ic"
                        label={<Trans>Supplier ICO</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>

                    <BoxFullWidth py={2} pl={1} className={classes.formTitle}>
                      <Trans>Recipient/subscriber</Trans>
                    </BoxFullWidth>
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="provider.name"
                        label={<Trans>Recipient</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="provider.ic"
                        label={<Trans>Recipient ICO</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>

                    <BoxFullWidth py={2} pl={1} className={classes.formTitle}>
                      <Trans>Final recipient</Trans>
                    </BoxFullWidth>
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="customer.name"
                        label={<Trans>Customer name</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>
                    <GridItem container xs={12} sm={6}>
                      <Field
                        name="customer.ic"
                        label={<Trans>Final recipient ICO</Trans>}
                        component={TextInput}
                        disabled={true}
                        filters={true}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridItem container xs={12}>
                <GridContainer
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <BoxFullWidth p={2} pt={6} className={classes.formTitle}>
                    <Trans>Tax document</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <GridItem container xs={4}>
                      <Field
                        name="tax_document_no"
                        label={<Trans>Tax document no.</Trans>}
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin}
                        inLineInput={true}
                        fullWidth={false}
                      />
                    </GridItem>
                    <GridItem xs={4} />
                    <GridItem container xs={4}>
                      <Field
                        name="confirmation_no"
                        label={<Trans>Confirmation number</Trans>}
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin}
                        inLineInput={true}
                        fullWidth={false}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem container xs={12}>
                    <GridItem container xs={4}>
                      <Field
                        name="date_issue"
                        label={
                          <CustomTooltip
                            title={
                              <Trans>
                                Press enter to format date and prefill due date and uzp date
                              </Trans>
                            }
                          >
                            <span className={classes.tooltipLabel}>
                              <Trans>Date issue</Trans>
                            </span>
                          </CustomTooltip>
                        }
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                        fullWidth={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        validate={
                          isDisabled
                            ? undefined
                            : composeValidators(
                                minDate(formProps.values.date_uzp),
                                maxDate(moment()),
                                required
                              )
                        }
                      />
                    </GridItem>
                    <GridItem xs={4} />
                    <GridItem container xs={4}>
                      <Field
                        name="order_no"
                        label={<Trans>Order number</Trans>}
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                        fullWidth={false}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem container xs={12}>
                    <GridItem container xs={4}>
                      <Field
                        name="date_due"
                        label={
                          <CustomTooltip title={<Trans>Press enter to format date</Trans>}>
                            <span className={classes.tooltipLabel}>
                              <Trans>Due date</Trans>
                            </span>
                          </CustomTooltip>
                        }
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                        fullWidth={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        validate={
                          isDisabled
                            ? undefined
                            : composeValidators(minDate(formProps.values.date_issue), required)
                        }
                      />
                    </GridItem>
                    <GridItem xs={4} />
                    <GridItem container xs={2}>
                      <Field
                        name="insured_event"
                        label={<Trans>Insured event</Trans>}
                        component={CheckBoxInput}
                        type={'checkbox'}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem container xs={12}>
                    <GridItem container xs={4}>
                      <Field
                        name="date_uzp"
                        label={
                          <CustomTooltip title={<Trans>Press enter to format date</Trans>}>
                            <span className={classes.tooltipLabel}>
                              <Trans>UZP date</Trans>
                            </span>
                          </CustomTooltip>
                        }
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                        fullWidth={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        validate={
                          isDisabled ? undefined : composeValidators(maxDate(moment()), required)
                        }
                      />
                    </GridItem>
                    <GridItem xs={4} />
                    <GridItem container xs={2}>
                      <Field
                        name="loan_vehicle"
                        label={<Trans>NV</Trans>}
                        component={CheckBoxInput}
                        type={'checkbox'}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem container xs={12}>
                    <GridItem container xs={4}>
                      <Field
                        name="date_uzp_outgoing"
                        label={
                          <CustomTooltip title={<Trans>Press enter to format date</Trans>}>
                            <span className={classes.tooltipLabel}>
                              <Trans>UZP date (outgoing)</Trans>
                            </span>
                          </CustomTooltip>
                        }
                        component={TextInput}
                        onChange={handleFieldChange}
                        disabled={!isAdmin || isDisabled}
                        inLineInput={true}
                        fullWidth={false}
                        onKeyDown={dateEnterEventHandler(formProps)}
                        validate={isDisabled ? undefined : composeValidators(maxDate(moment()))}
                      />
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* PRACOVNI OPERACE */}
              <GridItem container xs={12}>
                <GridContainer direction="row" justifyContent="flex-start" alignItems="center">
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Work operations</Trans>
                  </BoxFullWidth>

                  <DetailTableHeader />

                  {workItems?.map((item, index) => {
                    const hasCustomError =
                      itemTaxValidation(
                        formProps.values.date_uzp,
                        isSkProvider
                      )(formProps.values.workItem?.[index]?.tax) !== undefined
                    return (
                      <GridContainer
                        key={`work-item-${index}`}
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.paddingBottomRow}
                      >
                        <GridItem container xs={12} sm={4} md={2} lg={2}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].code`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `workItem.[${index}].code`}
                              onChange={(e) => handleItemChange('work', index, e)}
                            />
                          ) : (
                            <div
                              className={classes.staticField}
                              onClick={() => handleSetFocusedRow(`workItem.[${[index]}].code`)}
                            >
                              {formProps.values?.workItem?.[index]?.code}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={3}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].description`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `workItem.[${index}].description`}
                              onChange={(e) => handleItemChange('work', index, e)}
                            />
                          ) : (
                            <div
                              className={classes.staticField}
                              onClick={() =>
                                handleSetFocusedRow(`workItem.[${[index]}].description`)
                              }
                            >
                              {formProps.values?.workItem?.[index]?.description}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].unit_price`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `workItem.[${index}].unit_price`}
                              onChange={(e) => handleItemChange('work', index, e)}
                              inputJustifyRight={true}
                              type={'number'}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() =>
                                handleSetFocusedRow(`workItem.[${[index]}].unit_price`)
                              }
                            >
                              {formProps.values?.workItem?.[index]?.unit_price}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1} lg={1}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].quantity`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `workItem.[${index}].quantity`}
                              onChange={(e) => handleItemChange('work', index, e)}
                              inputJustifyRight={true}
                              type={'number'}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() => handleSetFocusedRow(`workItem.[${[index]}].quantity`)}
                            >
                              {formProps.values?.workItem?.[index]?.quantity}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={2}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].tax`}
                              component={SelectInput}
                              options={isSkProvider ? SK_DPH : CZ_DPH}
                              disabled={!isAdmin || isDisabled}
                              showHelperText={hasCustomError}
                              autoFocus={focusedRow === `workItem.[${index}].tax`}
                              onChange={(e) => handleItemChange('work', index, e)}
                              validate={itemTaxValidation(formProps.values.date_uzp, isSkProvider)}
                            />
                          ) : (
                            <div
                              className={cx(
                                classes.staticField,
                                hasCustomError ? classes.errorStaticField : ''
                              )}
                              onClick={() => handleSetFocusedRow(`workItem.[${[index]}].tax`)}
                            >
                              {formProps.values?.workItem?.[index]?.tax}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1} lg={1}>
                          {isAdmin && focusedRow?.includes(`workItem.[${index}]`) ? (
                            <Field
                              name={`workItem.[${index}].discount`}
                              component={TextInput}
                              disabled={!isAdmin || isDisabled}
                              showHelperText={false}
                              autoFocus={focusedRow === `workItem.[${index}].discount`}
                              onChange={(e) => handleItemChange('work', index, e)}
                              inputJustifyRight={true}
                              type={'number'}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() => handleSetFocusedRow(`workItem.[${[index]}].discount`)}
                            >
                              {formProps.values?.workItem?.[index]?.discount}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={2} lg={2} alignItems={'center'}>
                          <GridItem xs={true}>
                            <div className={cx(classes.staticField, classes.textAlignEnd)}>
                              {getPriceWithoutTax(formProps?.values?.workItem?.[index])}
                            </div>
                          </GridItem>
                          {isAdmin && (
                            <GridItem xs={'auto'}>
                              <IconButton
                                className={classes.removeItemButton}
                                onClick={handleRemoveItem('work', index, item)}
                              >
                                <Bin />
                              </IconButton>
                            </GridItem>
                          )}
                        </GridItem>
                      </GridContainer>
                    )
                  })}
                  {isAdmin && (
                    <GridContainer
                      spacing={2}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.paddingBottomRow}
                    >
                      <GridItem
                        xs={12}
                        className={classes.addItemButton}
                        onClick={handleAddItem('work', formProps.values.date_issue)}
                      >
                        <Trans>Add work operation</Trans>
                      </GridItem>
                    </GridContainer>
                  )}
                </GridContainer>
              </GridItem>

              {/* MATERIAL */}
              <GridItem container xs={12}>
                <GridContainer direction="row" justifyContent="flex-start" alignItems="center">
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Material</Trans>
                  </BoxFullWidth>

                  <DetailTableHeader />

                  {materialItems
                    ?.filter(
                      (materialItem) =>
                        materialItem?.line_type?.code === 'GOOD' ||
                        materialItem?.line_type_code === 'GOOD'
                    )
                    .map((item, index) => {
                      const hasCustomError =
                        itemTaxValidation(
                          formProps.values.date_uzp,
                          isSkProvider
                        )(formProps.values.workItem?.[index]?.tax) !== undefined
                      return (
                        <GridContainer
                          key={`material-good-${index}`}
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.paddingBottomRow}
                        >
                          <GridItem container xs={12} sm={4} md={2} lg={2}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].code`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `goodItem.[${index}].code`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() => handleSetFocusedRow(`goodItem.[${[index]}].code`)}
                              >
                                {formProps.values?.goodItem?.[index]?.code}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={3}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].description`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `goodItem.[${index}].description`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() =>
                                  handleSetFocusedRow(`goodItem.[${[index]}].description`)
                                }
                              >
                                {formProps.values?.goodItem?.[index]?.description}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].unit_price`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `goodItem.[${index}].unit_price`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`goodItem.[${[index]}].unit_price`)
                                }
                              >
                                {formProps.values?.goodItem?.[index]?.unit_price}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].quantity`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `goodItem.[${index}].quantity`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`goodItem.[${[index]}].quantity`)
                                }
                              >
                                {formProps.values?.goodItem?.[index]?.quantity}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].tax`}
                                component={SelectInput}
                                options={
                                  cfs?.provider?.id === 3 || cfs?.provider?.id === 4
                                    ? SK_DPH
                                    : CZ_DPH
                                }
                                disabled={isDisabled}
                                showHelperText={hasCustomError}
                                autoFocus={focusedRow === `goodItem.[${index}].tax`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                                validate={itemTaxValidation(
                                  formProps.values.date_uzp,
                                  isSkProvider
                                )}
                              />
                            ) : (
                              <div
                                className={cx(
                                  classes.staticField,
                                  hasCustomError ? classes.errorStaticField : ''
                                )}
                                onClick={() => handleSetFocusedRow(`goodItem.[${[index]}].tax`)}
                              >
                                {formProps.values?.goodItem?.[index]?.tax}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`goodItem.[${index}]`) ? (
                              <Field
                                name={`goodItem.[${index}].discount`}
                                component={TextInput}
                                disabled={!isAdmin || isDisabled}
                                showHelperText={false}
                                autoFocus={focusedRow === `goodItem.[${index}].discount`}
                                onChange={(e) => handleItemChange('material', index, e, 'GOOD')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`goodItem.[${[index]}].discount`)
                                }
                              >
                                {formProps.values?.goodItem?.[index]?.discount}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2} lg={2} alignItems={'center'}>
                            <GridItem xs={true}>
                              <div className={cx(classes.staticField, classes.textAlignEnd)}>
                                {getPriceWithoutTax(formProps?.values?.goodItem?.[index])}
                              </div>
                            </GridItem>
                            {isAdmin && (
                              <GridItem xs={'auto'}>
                                <IconButton
                                  className={classes.removeItemButton}
                                  onClick={handleRemoveItem('material', index, item)}
                                >
                                  <Bin />
                                </IconButton>
                              </GridItem>
                            )}
                          </GridItem>
                        </GridContainer>
                      )
                    })}
                  {materialItems
                    ?.filter(
                      (item) => item?.line_type?.code === 'ELIT' || item?.line_type_code === 'ELIT'
                    )
                    .map((item, index) => {
                      const hasCustomError =
                        itemTaxValidation(
                          formProps.values.date_uzp,
                          isSkProvider
                        )(formProps.values.workItem?.[index]?.tax) !== undefined
                      return (
                        <GridContainer
                          key={`material-elit-${index}`}
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.paddingBottomRow}
                        >
                          <GridItem container xs={12} sm={4} md={2} lg={2}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].code`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `elitItem.[${index}].code`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() => handleSetFocusedRow(`elitItem.[${[index]}].code`)}
                              >
                                {formProps.values?.elitItem?.[index]?.code}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={3}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].description`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `elitItem.[${index}].description`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() =>
                                  handleSetFocusedRow(`elitItem.[${[index]}].description`)
                                }
                              >
                                {formProps.values?.elitItem?.[index]?.description}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].unit_price`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `elitItem.[${index}].unit_price`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`elitItem.[${[index]}].unit_price`)
                                }
                              >
                                {formProps.values?.elitItem?.[index]?.unit_price}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].quantity`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `elitItem.[${index}].quantity`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`elitItem.[${[index]}].quantity`)
                                }
                              >
                                {formProps.values?.elitItem?.[index]?.quantity}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].tax`}
                                component={SelectInput}
                                options={
                                  cfs?.provider?.id === 3 || cfs?.provider?.id === 4
                                    ? SK_DPH
                                    : CZ_DPH
                                }
                                disabled={isDisabled}
                                showHelperText={hasCustomError}
                                autoFocus={focusedRow === `elitItem.[${index}].tax`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                                validate={itemTaxValidation(
                                  formProps.values.date_uzp,
                                  isSkProvider
                                )}
                              />
                            ) : (
                              <div
                                className={cx(
                                  classes.staticField,
                                  hasCustomError ? classes.errorStaticField : ''
                                )}
                                onClick={() => handleSetFocusedRow(`elitItem.[${[index]}].tax`)}
                              >
                                {formProps.values?.elitItem?.[index]?.tax}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`elitItem.[${index}]`) ? (
                              <Field
                                name={`elitItem.[${index}].discount`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `elitItem.[${index}].discount`}
                                onChange={(e) => handleItemChange('material', index, e, 'ELIT')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`elitItem.[${[index]}].discount`)
                                }
                              >
                                {formProps.values?.elitItem?.[index]?.discount}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2} lg={2} alignItems={'center'}>
                            <GridItem xs={true}>
                              <div className={cx(classes.staticField, classes.textAlignEnd)}>
                                {getPriceWithoutTax(formProps?.values?.elitItem?.[index])}
                              </div>
                            </GridItem>
                            {isAdmin && (
                              <GridItem xs={'auto'}>
                                <IconButton
                                  className={classes.removeItemButton}
                                  onClick={handleRemoveItem('material', index, item)}
                                >
                                  <Bin />
                                </IconButton>
                              </GridItem>
                            )}
                          </GridItem>
                        </GridContainer>
                      )
                    })}

                  {materialItems
                    ?.filter(
                      (item) => item?.line_type?.code === 'OE' || item?.line_type_code === 'OE'
                    )
                    .map((item, index) => {
                      const hasCustomError =
                        itemTaxValidation(
                          formProps.values.date_uzp,
                          isSkProvider
                        )(formProps.values.workItem?.[index]?.tax) !== undefined
                      return (
                        <GridContainer
                          key={`material-oe-${index}`}
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.paddingBottomRow}
                        >
                          <GridItem container xs={12} sm={4} md={2} lg={2}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].code`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].code`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() => handleSetFocusedRow(`oeItem.[${[index]}].code`)}
                              >
                                {formProps.values?.oeItem?.[index]?.code}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={3}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].description`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].description`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                              />
                            ) : (
                              <div
                                className={classes.staticField}
                                onClick={() =>
                                  handleSetFocusedRow(`oeItem.[${[index]}].description`)
                                }
                              >
                                {formProps.values?.oeItem?.[index]?.description}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].unit_price`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].unit_price`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() =>
                                  handleSetFocusedRow(`oeItem.[${[index]}].unit_price`)
                                }
                              >
                                {formProps.values?.oeItem?.[index]?.unit_price}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].quantity`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].quantity`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() => handleSetFocusedRow(`oeItem.[${[index]}].quantity`)}
                              >
                                {formProps.values?.oeItem?.[index]?.quantity}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].tax`}
                                component={SelectInput}
                                options={
                                  cfs?.provider?.id === 3 || cfs?.provider?.id === 4
                                    ? SK_DPH
                                    : CZ_DPH
                                }
                                showHelperText={hasCustomError}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].tax`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                                validate={itemTaxValidation(
                                  formProps.values.date_uzp,
                                  isSkProvider
                                )}
                              />
                            ) : (
                              <div
                                className={cx(
                                  classes.staticField,
                                  hasCustomError ? classes.errorStaticField : ''
                                )}
                                onClick={() => handleSetFocusedRow(`oeItem.[${[index]}].tax`)}
                              >
                                {formProps.values?.oeItem?.[index]?.tax}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={1} lg={1}>
                            {isAdmin && focusedRow?.includes(`oeItem.[${index}]`) ? (
                              <Field
                                name={`oeItem.[${index}].discount`}
                                component={TextInput}
                                showHelperText={false}
                                disabled={isDisabled}
                                autoFocus={focusedRow === `oeItem.[${index}].discount`}
                                onChange={(e) => handleItemChange('material', index, e, 'OE')}
                                inputJustifyRight={true}
                                type={'number'}
                              />
                            ) : (
                              <div
                                className={cx(classes.staticField, classes.textAlignEnd)}
                                onClick={() => handleSetFocusedRow(`oeItem.[${[index]}].discount`)}
                              >
                                {formProps.values?.oeItem?.[index]?.discount}
                              </div>
                            )}
                          </GridItem>
                          <GridItem container xs={12} sm={4} md={2} lg={2} alignItems={'center'}>
                            <GridItem xs={true}>
                              <div className={cx(classes.staticField, classes.textAlignEnd)}>
                                {getPriceWithoutTax(formProps?.values?.oeItem?.[index])}
                              </div>
                            </GridItem>
                            {isAdmin && (
                              <GridItem xs={'auto'}>
                                <IconButton
                                  className={classes.removeItemButton}
                                  onClick={handleRemoveItem('material', index, item)}
                                >
                                  <Bin />
                                </IconButton>
                              </GridItem>
                            )}
                          </GridItem>
                        </GridContainer>
                      )
                    })}

                  {isAdmin && (
                    <GridContainer
                      spacing={2}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.paddingBottomRow}
                    >
                      <GridItem
                        xs={12}
                        className={classes.addItemButton}
                        onClick={handleAddItem('material', formProps.values.date_issue)}
                      >
                        <Trans>Add material</Trans>
                      </GridItem>
                    </GridContainer>
                  )}
                </GridContainer>
              </GridItem>

              {/* VYBRANE PRACE */}
              <GridItem container xs={12}>
                <GridContainer direction="row" justifyContent="flex-start" alignItems="center">
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Selected jobs</Trans>
                  </BoxFullWidth>

                  <DetailTableHeader />

                  {predefinedItems?.map((item, index) => {
                    const hasCustomError =
                      itemTaxValidation(
                        formProps.values.date_uzp,
                        isSkProvider
                      )(formProps.values.workItem?.[index]?.tax) !== undefined
                    return (
                      <GridContainer
                        key={`predefined-item-${index}`}
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.paddingBottomRow}
                      >
                        <GridItem container xs={12} sm={4} md={2} lg={2}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].code`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].code`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                            />
                          ) : (
                            <div
                              className={classes.staticField}
                              onClick={() =>
                                handleSetFocusedRow(`predefinedItem.[${[index]}].code`)
                              }
                            >
                              {formProps.values?.predefinedItem?.[index]?.code}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={3}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].description`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].description`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                            />
                          ) : (
                            <div
                              className={classes.staticField}
                              onClick={() =>
                                handleSetFocusedRow(`predefinedItem.[${[index]}].description`)
                              }
                            >
                              {formProps.values?.predefinedItem?.[index]?.description}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].unit_price`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].unit_price`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                              inputJustifyRight={true}
                              type={'number'}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() =>
                                handleSetFocusedRow(`predefinedItem.[${[index]}].unit_price`)
                              }
                            >
                              {formProps.values?.predefinedItem?.[index]?.unit_price}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1} lg={1}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].quantity`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].quantity`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                              inputJustifyRight={true}
                              type={'number'}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() =>
                                handleSetFocusedRow(`predefinedItem.[${[index]}].quantity`)
                              }
                            >
                              {formProps.values?.predefinedItem?.[index]?.quantity}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={2}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].tax`}
                              component={SelectInput}
                              options={
                                cfs?.provider?.id === 3 || cfs?.provider?.id === 4 ? SK_DPH : CZ_DPH
                              }
                              showHelperText={hasCustomError}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].tax`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                              validate={itemTaxValidation(formProps.values.date_uzp, isSkProvider)}
                            />
                          ) : (
                            <div
                              className={cx(
                                classes.staticField,
                                hasCustomError ? classes.errorStaticField : ''
                              )}
                              onClick={() => handleSetFocusedRow(`predefinedItem.[${[index]}].tax`)}
                            >
                              {formProps.values?.predefinedItem?.[index]?.tax}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={1} lg={1}>
                          {isAdmin && focusedRow?.includes(`predefinedItem.[${index}]`) ? (
                            <Field
                              name={`predefinedItem.[${index}].discount`}
                              component={TextInput}
                              showHelperText={false}
                              disabled={isDisabled}
                              autoFocus={focusedRow === `predefinedItem.[${index}].discount`}
                              onChange={(e) => handleItemChange('predefined', index, e)}
                              inputJustifyRight={true}
                            />
                          ) : (
                            <div
                              className={cx(classes.staticField, classes.textAlignEnd)}
                              onClick={() =>
                                handleSetFocusedRow(`predefinedItem.[${[index]}].discount`)
                              }
                            >
                              {formProps.values?.predefinedItem?.[index]?.discount}
                            </div>
                          )}
                        </GridItem>
                        <GridItem container xs={12} sm={4} md={2} lg={2} alignItems={'center'}>
                          <GridItem xs={true}>
                            <div className={cx(classes.staticField, classes.textAlignEnd)}>
                              {getPriceWithoutTax(formProps?.values?.predefinedItem?.[index])}
                            </div>
                          </GridItem>
                          {isAdmin && (
                            <GridItem xs={'auto'}>
                              <IconButton
                                className={classes.removeItemButton}
                                onClick={handleRemoveItem('predefined', index, item)}
                              >
                                <Bin />
                              </IconButton>
                            </GridItem>
                          )}
                        </GridItem>
                      </GridContainer>
                    )
                  })}

                  {isAdmin && (
                    <GridContainer
                      spacing={2}
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={classes.paddingBottomRow}
                    >
                      <GridItem
                        xs={12}
                        className={classes.addItemButton}
                        onClick={handleAddItem('predefined', formProps.values.date_issue)}
                      >
                        <Trans>Add selected job</Trans>
                      </GridItem>
                    </GridContainer>
                  )}
                </GridContainer>
              </GridItem>

              <GridItem container xs={12}>
                <GridContainer
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <BoxFullWidth p={2} pt={6} className={classes.formTitle}>
                    <Trans>Summary</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <GridItem container xs={3}>
                      <TextInput
                        disabled={true}
                        showHelperText={false}
                        label={<Trans>Total without tax</Trans>}
                        value={Math.round(getTotal(formProps?.values, false) * 100) / 100}
                        inLineInput={true}
                        fullWidth={false}
                        noInlineFlex={true}
                        className={classes.textAlignEnd}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem container xs={12}>
                    <GridItem container xs={3}>
                      <TextInput
                        disabled={true}
                        showHelperText={false}
                        label={<Trans>Total tax</Trans>}
                        value={
                          Math.round(
                            Number(
                              getTotal(formProps?.values, true) -
                                Number(getTotal(formProps?.values, false))
                            ) * 100
                          ) / 100
                        }
                        inLineInput={true}
                        fullWidth={false}
                        noInlineFlex={true}
                        className={classes.textAlignEnd}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem container xs={12}>
                    <GridItem container xs={3}>
                      <TextInput
                        disabled={true}
                        showHelperText={false}
                        label={<Trans>Total with tax</Trans>}
                        value={Math.round(getTotal(formProps?.values, true) * 100) / 100}
                        inLineInput={true}
                        fullWidth={false}
                        noInlineFlex={true}
                        className={classes.textAlignEnd}
                      />
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem container xs={12}>
                {/* LAST ATTACHMENTS */}
                <GridItem xs={6} className={classes.attachmentTablePadding}>
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Last attachments</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12} className={classes.overflowAuto}>
                    <Table
                      data={cfs?.attachments?.filter((att) => att.actual)}
                      columns={attachmentColumns}
                      loading={cfsLoading}
                      pagination={false}
                      className={cx(classes.lastRowRoundBorder, classes.detailTable)}
                    />
                  </GridItem>
                </GridItem>

                {/* ORIGINAL ATTACHMENTS */}
                <GridItem xs={6} className={classes.attachmentTablePadding}>
                  <BoxFullWidth pb={2} pt={6} className={classes.formTitle}>
                    <Trans>Original attachments</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12}>
                    <Table
                      data={cfs?.attachments?.filter((att) => !att.actual)}
                      columns={attachmentColumns}
                      loading={cfsLoading}
                      pagination={false}
                      className={cx(classes.lastRowRoundBorder, classes.detailTable)}
                    />
                  </GridItem>
                </GridItem>
              </GridItem>

              <GridItem container xs={12}>
                <GridContainer
                  spacing={4}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <BoxFullWidth p={2} pt={6} className={classes.formTitle}>
                    <Trans>Changelog</Trans>
                  </BoxFullWidth>
                  <GridItem container xs={12} className={classes.overflowAuto}>
                    <Table
                      data={changeLog.sort((a, b) => b.id - a.id)}
                      columns={changeLogColumns}
                      loading={changeLogLoading}
                      pagination={false}
                      className={cx(classes.lastRowRoundBorder, classes.detailTable)}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <BoxFullWidth mt={6}>
                <GridItem container xs={12}>
                  <GridContainer direction="row" justifyContent="flex-end" alignItems="center">
                    <PrimaryButton
                      disabled={formProps.submitting || hasFormTaxError(formProps.values)}
                      type="submit"
                      size={'large'}
                      fullWidth={false}
                      textPadding={0}
                      text={<Trans>Save</Trans>}
                    />
                  </GridContainer>
                </GridItem>
              </BoxFullWidth>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

CFSDetail.propTypes = {
  classes: PropTypes.object,
  getCfs: PropTypes.func,
  cfs: PropTypes.object,
  cfsLoading: PropTypes.bool,
  getInvoiceStatuses: PropTypes.func,
  invoiceStatusList: PropTypes.array,
  invoiceStatusListLoading: PropTypes.bool,
  patchCfs: PropTypes.func,
  getInvoiceChangeLog: PropTypes.func,
  changeLog: PropTypes.array,
  changeLogLoading: PropTypes.bool,
  getFleet: PropTypes.func,
  fleetLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCfs,
      getInvoiceStatuses,
      patchCfs,
      getInvoiceChangeLog,
      getFleet,
    },
    dispatch
  )
}

export default compose(
  withStyles(detailPageStyle),
  connect((store) => {
    return {
      cfs: store.cfs.cfs,
      cfsLoading: store.cfs.cfsLoading,
      invoiceStatusList: store.cfs.invoiceStatusList,
      invoiceStatusListLoading: store.cfs.invoiceStatusListLoading,
      changeLog: store.cfs.changeLog,
      changeLogLoading: store.cfs.changeLogLoading,
      fleetLoading: store.fleet.fleetLoading,
    }
  }, mapDispatchToProps)
)(CFSDetail)
